import React from "react"

import PageLayout from "components/PageLayout"
import {VideoSection} from "components/HeroSection"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import HomeCarousel from "components/HomeCarousel"
import DynamicCards from "components/DynamicCards"

import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles"
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js"
import Typography from '@material-ui/core/Typography'
import Quote from "components/Typography/Quote.js";

import SEO from "../components/Seo"
import useSiteMetadata from "../hooks/use-site-metadata"

const useStyles = makeStyles(landingPageStyle)

// export const query = graphql`
// {
//   cmsAPI{
//     aboutSingleton {
//       ...SiteTitle
//     }
//   }
// }
// `


export default (location) => {
  const classes = useStyles()
  const { logo,logo_black } = useSiteMetadata()
  return (
    <PageLayout>
      <SEO
          title="Αρχική"
          description="Complete Construction"
          image={logo_black}
          pathname={location.pathname}
        />
      <VideoSection image={"https://images.unsplash.com/photo-1435575653489-b0873ec954e2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1350&q=80"} video={"./completeconstruction_hero"}/>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          {/* <GridContainer> */}
          {/* <HomeCarousel /> */}
            {/* <GridItem xs={12} sm={6} md={6}>
              <Typography variant="body1" gutterBottom color='secondary'>
              <Quote
      text="I will be the leader of a company that ends up being worth billions of dollars, because I got the answers. I understand culture. I am the nucleus. I think that’s a responsibility that I have, to push possibilities, to show people, this is the level that things could be at."
      author=" Kanye West, Musician"
    
                />
              </Typography>
            </GridItem> */}
            {/* <GridItem
              md={8}
              sm={8}
              className={classNames(classes.mrAuto, classes.mlAuto)}
            >
            <h2 className={classNames(classes.title, classes.textCenter)}>
                <a id="philosophy"></a>Τελευταία έργα
              </h2>
              </GridItem>
            <GridItem xs={12} sm={6} md={6} className={classes.marginAuto}>
              <HomeCarousel />
            </GridItem> */}
          {/* </GridContainer> */}
          <DynamicCards />
        </div>
      </div>
      </PageLayout>
  )
}
