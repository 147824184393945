import React from "react"

import ParallaxSection from "components/ParallaxSection"

import { makeStyles } from "@material-ui/core/styles"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js"

const useStyles = makeStyles(landingPageStyle)

const HeroSection = ({image}) => {
  const classes = useStyles()

  return (
    <ParallaxSection image={image} filter={"dark"} containerClass={classes.container}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <h1 className={classes.title}>We make it true.</h1>
          {/* <h4>
            Every landing page needs a small description after the big bold
            title, that{"'"}s why we added this text here. Add here all the
            information that can make you or your product create the first
            impression.
          </h4>
          <br />
          <Button
            color="danger"
            size="lg"
            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
            target="_blank"
          >
            <i className="fas fa-play" />
            Watch video
          </Button> */}
        </GridItem>
      </GridContainer>
    </ParallaxSection>
  )
}

export const VideoSection = ({image, video}) => {
  const classes = useStyles()

  console.log(video)

  return (
    <ParallaxSection image={image} filter={"dark"}>
    <video src={video} autoPlay loop muted style={{ width:"100vw"}}/> 
    </ParallaxSection>
  )
}

export default HeroSection
